import { Box, Link, Flex, Stack, Button, IconButton, Center, Heading, LightMode, SimpleGrid, Text, Spacer, VStack, useDisclosure, Image, Container, useBreakpointValue } from '@chakra-ui/react'
import * as React from 'react'
import { FiCalendar, FiSend } from 'react-icons/fi'
import bg1 from './../../Media/images/depot.png'
import { ReactTyped } from "react-typed";
import { Timer, TimerBan } from './timer';


export const FramerChambre = () => {
  const { isOpen, onClose, onToggle } = useDisclosure()

  return (
    <>
      <Container
        maxW="2xl"
        maxH="2xl"
        p={{ base: 5, md: 5 }}

      >
        <Stack spacing={4} minW={{ base: 'full', md: 'auto' }} margin="0 auto"

        >

          <Box pos="relative">
            <Box
              pos="absolute"
              top="-7px"
              right="-7px"
              bottom="-7px"
              left="-7px"
              rounded="lg"
              bgGradient="linear(to-l, brand.primary.100,brand.secondary)"
              transform="rotate(-3deg)"
              opacity={0.8}
            ></Box>
            <VStack
              as="form"
              pos="relative"
              spacing={8}
              p={6}
            // rounded="lg"
            // boxShadow="lg"
            >
              <Stack align="center" spacing={2}>
                <Heading color="white" fontSize={{ base: 'md', sm: '3xl' }}>Dépôt de candidature</Heading>
              </Stack>
              <Flex>
                <Image
                  rounded={'md'}
                  alt={'feature image'}
                  src={
                    bg1}
                  objectFit={'cover'}
                  boxSize={{ base: 'auto', md: '300px' }}

                />
              </Flex>
              <Stack direction={'row'}>
                <Box
                  size={{ base: "sm", md: "2xl" }}
                >
                  {/* <Button
                    w="full"
                    // leftIcon={<FiCalendar />}
                    textTransform="uppercase"
                    bgGradient="linear(to-tr, brand.primary.100, brand.secondary)"

                    color={"white"}
                    onClick={onToggle}
                  > */}
                  <TimerBan />
                  {/* </Button> */}

                </Box>
              </Stack>
              {/* <Button
                bg="blue.400"
                color="white"
                _hover={{
                  bg: 'blue.500'
                }}
                rounded="md"
                w="100%"
              >
                Send magic link
              </Button> */}
            </VStack>
          </Box>
        </Stack>
      </Container>

    </>
  )
}
const TypedText = () => {
  return (
    <div>
      <Heading fontWeight="extrabold"
        // bgClip='text'
        fontSize={{ base: "24px", lg: "30px" }}
        color={"whiteAlpha.900"}
        align="center"
      >
        {" "}
        <ReactTyped
          strings={["Pour des élections libres,", "transparentes et crédible"]}
          typeSpeed={100}
          loop
          backSpeed={20}
          cursorChar=">"
          showCursor={true}
        />
      </Heading>
    </div>
  );
};

export const Banner = () => {
  return (
    <Box
      as="section"
      bg="gray.200"
      py="12"
      position="relative"
      minH={{ base: '560px', md: '640px' }}
      bgImage={"https://images.unsplash.com/photo-1509099955921-f0b4ed0c175c?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        bg: 'blackAlpha.300',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >

      <VStack
        w={'full'}
        // justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
      // bgGradient={useColorModeValue('linear(to-t, brand.primary, transparent)', 'linear(to-t, brand.primary, transparent)')}

      >

        <Spacer />
        <Stack minW={{ base: "auto", md: 'full' }} direction={{ base: "column", md: "row" }} align={'center'} spacing={6}>
          <Stack minW={{ base: "auto", lg: '5xl' }} align={'center'} spacing={1}>
            <Box
              maxW={{ base: 'xl', md: '3xl', lg: '7xl' }}
              mx="auto"
              px={{ base: '6', md: '8' }}
              h="full"
              zIndex={1}
              position="relative"
            >
              <Center flexDirection="column" textAlign="center" h="full">
                {/* <Heading
                  // size={{base: "md", md: "lg"}} 
                  // bgClip='text'
                  fontWeight="extrabold"
                  fontSize={{ base: "12px", md: "18px", lg: "34px" }}
                  // bgClip='text'
                  // bgGradient='linear(to-r,brand.jaune.900, brand.primary.100)'
                  // bgGradient='linear(to-b, brand.primary.100, brand.secondary)'
                  color={"white"}
                >
                  AGENCE NATIONALE DE GESTION DES ELECTIONS
                </Heading> */}
                <Heading
                  as="h1"
                  size={{ base: "md", md: "lg" }}
                  color={"brand.secondary"}
                  mt="8"
                  fontWeight="extrabold"
                  letterSpacing="tight"
                  textAlign='center'
                >
                  Agence Nationale de Gestion des Élections
                </Heading>
                <TypedText

                />

              </Center>
            </Box>
          </Stack>
          <Spacer display={{ base: 'none', lg: 'block' }} />
          <Container maxW="full" py={10} px={{ base: 5, md: 8 }}
            display={{ base: 'none', lg: 'block' }}
          >
            <FramerChambre />
          </Container>
        </Stack>
        <Spacer />

        <Box
          display={{ base: 'none', md: 'block' }}
          position="absolute"
          zIndex={2}
          w="full"
          bottom="0"
          py="4"
          bg="blackAlpha.700"
        >
          <Box minW={{ base: 'xl', md: 'full' }} mx="auto">
            <SimpleGrid columns={{ base: 1, md: 4 }} color="brand.ligthGrey">
              <Box textAlign="center" color="white">
                <Text
                  bgGradient="linear(to-tr, brand.primary.100, brand.secondary)"
                  bgClip='text'
                  fontSize="xl" fontWeight={"bold"}>Estimation Population</Text>
                <Text fontSize="2xl">16M+</Text>
              </Box>
              <Box textAlign="center" color="white">
                <Text
                  bgGradient="linear(to-tr, brand.primary.100, brand.secondary)"
                  bgClip='text'
                  fontSize="xl" fontWeight={"bold"}>Nombre d'électeurs</Text>
                <Text fontSize="2xl">8M+</Text>
              </Box>
              <Box textAlign="center" color="white">
                <Text
                  bgGradient="linear(to-tr, brand.primary.100, brand.secondary)"
                  bgClip='text'
                  fontSize="xl" fontWeight={"bold"}>Sufrages</Text>
                <Text fontSize="2xl">65%</Text>
              </Box>
              <Box textAlign="center" color="white">
                <Text
                  bgGradient="linear(to-tr, brand.primary.100, brand.secondary)"
                  bgClip='text'
                  fontSize="xl"
                  fontWeight={"bold"}>Bureaux de vote</Text>
                <Text fontSize="2xl">23K</Text>
              </Box>
            </SimpleGrid>
          </Box>
        </Box>
      </VStack>
    </Box>
  )
}