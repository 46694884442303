import { Box, Stack } from '@chakra-ui/react'
import { ImageWithOverlay } from './ImageWithOverlay'
import Milestones from './timeline'

export const Showcase = () => (
  <Box
    maxW="7xl"
    mx="auto"
    px={{ base: '4', md: '8', lg: '12' }}
    py={{ base: '6', md: '8', lg: '12' }}
    shadow='lg' borderBottomWidth={6} borderBottomColor={"red.500"}
    as="section"
    mb={10}
  >
  <Milestones/>
  </Box>
)
