
export const links = [
  {
    title: 'Service en lignes',
    links: [
      { label: "Actualités de l'ANGE", href: '#' },
      { label: 'Accréditation de presse', href: '#' },
      { label: 'Accréditation Observateur', href: '#' },

    ],
  },
  {
    title: 'Liens utiles',
    links: [
      { label: 'Présidence du Tchad', href: '#' },
      { label: 'Conseil Constitutionnel', href: '#' },
      { label: 'SG du gouvernement', href: '#' },

    ],
  },
  {
    title: 'Partenaires ',
    links: [
      { label: 'PNUD', href: '#' },
      { label: 'Union Europeen', href: '#' },
      { label: 'CEEAC', href: '#' },
    ],
  },
  {
    title: 'Coordonnees',
    links: [
      { label: 'Mail: ange-tchad@ange.td', href: '#' },
      { label: "Adresse: Avenue du 26 Août, N'Djamena", href: '#' },
      { label: 'Tel.: xxxxxxxxx', href: '#' },
    ],
  },
  // {
  //   title: 'Legal',
  //   links: [
  //     { label: 'Terms of Service', href: '#' },
  //     { label: 'Privacy Policy', href: '#' },
  //     { label: 'Offer terms', href: '#' },
  //     { label: 'License', href: '#' },
  //   ],
  // },
]
