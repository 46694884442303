import {
    Avatar,
    AvatarGroup,
    Box,
    Center,
    DarkMode,
    Flex,
    Heading,
    HStack,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue as mode,
    ScaleFade,
    useDisclosure
} from '@chakra-ui/react'
import * as React from 'react'
import LoginForm from './LoginForm'
import LogoSA, { LogoSAVert } from '../Header/LogoSA'

const Login = () => {
    const { isOpen, onOpen, onToggle } = useDisclosure()
    const loginPaddingBreakpoint = useBreakpointValue(
        {
            base: "0",
            md: "5"
        }
    )
    const loginsizeBreakpoint = useBreakpointValue(
        {
            xl: "lg",
            lg: "xs",
            md: "xs",
            sm: "lg",
            base: "xs",
        }
    )

    return (
        <Box bgGradient={{ sm: 'linear(to-bl, brand.primary.100, black)' }} >
            <Box p={{ base: 'auto', md: '10%' }} h="95vh">
                <Box
                    m={'auto'}
                    w={{ base: "full", md: "600px" }}

                    bg="white"
                    shadow={{
                        md: 'lg',
                    }}
                    borderBottomWidth={4}
                    borderBottomColor={"brand.primary.100"}
                >
                    <Box
                        align="center"
                        color={"brand.primary.100"}
                        bg={"white"}
                    >

                        <Center>


                            <LogoSAVert
                                title=" Administration"
                            />

                        </Center>
                        <Text>Pour des élections libres, transparentes et credibles</Text>
                    </Box>
                    <Box


                        align="center"
                    >
                        {!isOpen &&

                            <ScaleFade initialScale={0.1} in={!isOpen}>
                                <Box pb={'10'} p={loginPaddingBreakpoint}
                                    borderBottomWidth={4} borderBottomColor={"brand.primary.100"}
                                    px={4}
                                >
                                    <LoginForm />
                                </Box>
                            </ScaleFade>

                        }
                        {isOpen &&
                            <ScaleFade initialScale={0.1} in={isOpen}>
                                <Text
                                    as="h1"
                                    size={loginsizeBreakpoint}
                                    letterSpacing="tight"
                                    color={"brand.orange.100"}
                                    align={"center"}

                                >
                                    Login
                                </Text>

                            </ScaleFade>
                        }
                    </Box>
                </Box>



            </Box>
            <Flex align="center" h="24">
                <Text color="white" fontSize="sm">
                    © 2024 Naibi SSO . All rights reserved.
                </Text>
            </Flex>

        </Box>
    )
}
export default Login