import { Center, Container, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import Pnud from '../../Media/images/PNUD.png'
import Ue from '../../Media/images/UE.png'
import ceeac from '../../Media/images/ceeac-logo.jpg'
export const Partenaire = () => (
  <Container py={{ base: '12', md: '16' }}>
    <Stack spacing="8">
      <Text
        fontSize={{ base: 'md', md: 'lg' }}
        fontWeight="medium"
        color="white"
        textAlign="center"
      >
        Nos partenaires
      </Text>
      <SimpleGrid gap={{ base: '4', md: '6' }} columns={{ base: 1, md: 2, lg:3 }}>

          <Center
            bg="bg.surface"
            py={{ base: '4', md: '8' }}
            boxShadow="sm"
            borderRadius="lg"
          >
            <Image src={Pnud} h={{ base: '8', md: '10' }} w="auto"  />
          </Center>

          <Center
            bg="bg.surface"
            py={{ base: '4', md: '4' }}
            boxShadow="sm"
            borderRadius="lg"
          >
            <Image src={Ue} h={{ base: '8', md: '10' }} w="auto"  />
          </Center>

          <Center
            bg="bg.surface"
            py={{ base: '4', md: '8' }}
            boxShadow="sm"
            borderRadius="lg"
          >
            <Image src={ceeac} h={{ base: '8', md: '10' }} w="auto"  />
          </Center>
      </SimpleGrid>
    </Stack>
  </Container>
)