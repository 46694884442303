import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Container,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    HStack,
    IconButton,
    useBreakpointValue,
    useDisclosure,
    Spacer,
    Heading,
    useColorMode,
    Stack,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Image,
} from '@chakra-ui/react'
import * as React from 'react'
import {
    FiBarChart2,
    FiBookmark,
    FiCheckSquare,
    FiHome,
    FiHelpCircle, FiSearch, FiSettings, FiUser
} from 'react-icons/fi'
import LogoSA from './LogoSA'
import { Sidebar } from './Sidebar'
import { ToggleButton } from './ToggleButton'
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from '../Context'
import { useContext, useEffect } from 'react'
import {

    SunIcon,
    MoonIcon,
    ChevronDownIcon
} from '@chakra-ui/icons';
import { NavButton } from './NavButton'
import { FaBullhorn, FaCpanel, FaEnvelope, FaFileInvoiceDollar, FaGlobeEurope, FaIdCard, FaLockOpen, FaTools, FaUser, FaUserCog, FaUserFriends, FaUsers } from 'react-icons/fa'
import { UserProfile } from './UserProfile'
import Profil from '../Profil'
import { MdBuild } from 'react-icons/md'
import MenuPrincipalBody from './menu'
import { UilRestaurant, UilGlassMartiniAlt, UilHouseUser } from '@iconscout/react-unicons'
const Navbar = ({ menuData }) => {
    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
    })
    const { colorMode, toggleColorMode } = useColorMode();
    const navigate = useNavigate()
    const { isOpen: isOpenProfil, onClose: onCloseProfil, onToggle: onToggleProfil } = useDisclosure()
    const { isOpen, onClose, onToggle } = useDisclosure()
    const { login, dataUser, token, logout, retrieve } = useContext(GlobalContext)
    useEffect(() => {
        retrieve()
    }, [])
    return (
        <section>
            <Box as="nav" 
            bg={"brand.primary.100"}
            // bgGradient={'linear(to-r, brand.primary.100, brand.secondary)'} 
            color="white" px={8} fontSize={{ base: "sm", md: "md" }} >
                <Flex minWidth='max-content' alignItems='center'
                // py="3"

                >
                    {/* <Logo /> */}
                    <Link to="/"><LogoSA /></Link>

                    <Spacer />

                    <HStack >
                        {isDesktop && (
                            <ButtonGroup variant="ghost-on-accent" spacing="1" >
                               
                            </ButtonGroup>
                        )}
                    </HStack>
                    <Spacer />
                    {isDesktop ? (
                        <Flex w="100%" justifyContent="space-between" pl="10">
                             
                            <HStack spacing="1"
                            // color="brand.secondary"

                            >
                                {!isOpen && <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />}
                                <Link href="/">
                                    <NavButton label="Accueil" icon={FiHome} />
                                </Link>
                                <Link to="/#">
                                    <NavButton label="Presentation" icon={FaIdCard} aria-current="page" />
                                </Link>
                                <Link to="/#">
                                    <NavButton label="Election" icon={FaBullhorn} />
                                </Link>
                                <Link to="/#">
                                    <NavButton label="Vote" icon={FaEnvelope} />
                                </Link>

                            </HStack>

                            <HStack
                                // bgGradient="linear(to-b, brand.primary.100, brand.secondary)"
                                // borderRadius={2}
                                justify={"space-between"}
                            >

                                <ButtonGroup variant="ghost-on-accent" spacing="1">
                                    {!token &&

                                        <Link to="/login">
                                            <NavButton label="Login" icon={FiUser} />
                                        </Link>
                                    }


                                    {token &&

                                        <Button
                                            fontSize={'sm'}
                                            fontWeight={600}
                                            color={'white'}
                                            onClick={onToggle}
                                        >
                                            <HStack>
                                                <Avatar
                                                    size='xs'
                                                    name={dataUser?.user?.photo_full ? `${dataUser?.user?.photo_full?.alt}` : dataUser?.user?.mail}
                                                    src={`${dataUser?.user?.photo_full?.url}`}
                                                    mr='12px'
                                                />
                                                <span>{dataUser?.user?.nom ? `${dataUser?.user?.nom}` : dataUser?.user?.mail}</span>


                                            </HStack>

                                        </Button>

                                    }
                                    <Button onClick={toggleColorMode}>
                                        {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                                    </Button>
                                </ButtonGroup>


                            </HStack>
                        </Flex>
                    ) : (
                        <>
                            {!isOpen && <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />}

                        </>
                    )}
                    <MenuPrincipal isOpen={isOpen} onClose={onClose} dataUser={dataUser}
                        navigate={navigate}
                        logout={logout}
                        menuData={menuData}
                    />

                </Flex>
            </Box>

        </section>
    )
}
const MenuPrincipal = ({
    isOpen,
    onClose,
    dataUser,
    logout,
    navigate,
    menuData
}) => {
    return (
        <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            // isFullHeight
            preserveScrollBarGap
            // Only disabled for showcase
            maxW={{ base: 'full', sm: 'sm' }}
            trapFocus={false}

        >
            <DrawerOverlay />
            <DrawerContent
                bgGradient={'linear(to-r, brand.primary.100, brand.secondary)'}

            >
                <MenuPrincipalBody menuData={menuData} user={dataUser?.user} logout={logout} />
                {/* <Sidebar dataUser={dataUser} logout={logout} navigate={navigate}/> */}
            </DrawerContent>

        </Drawer>
    )
}
export default Navbar