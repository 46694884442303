
import { Box, Badge, SimpleGrid, Image, Link, Text } from '@chakra-ui/react';
import { Button, Center, Container, Divider, HStack, Stack, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Tooltip, Wrap, WrapItem, useBreakpointValue } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { BsInboxFill } from 'react-icons/bs';
import { FaNewspaper } from 'react-icons/fa';

const dataList = [
  {
    id: 1,
    title: 'PROGRAMME DES ELECTIONS LEGISLATIVES, COMMUNALES ET PROVINCIALES',
    authorName: 'administrateur',
    img: "https://images.unsplash.com/photo-1504598578017-40d9b776f1bc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  },
  {
    id: 2,
    title: 'PROCLAMATION DES RESULTATS PROVISOIRES DE L’ELECTION PRESIDENTIELLE',
    authorName: 'administrateur',
    img:"https://images.unsplash.com/photo-1650499310282-cda4d957f5be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  },
  {
    id: 3,
    title: 'ATELIER DE FORMATION DES FORMATEURS DES MEMBRES DU BUREAU DE VOTE',
    authorName: 'Administrateur',
    img: "https://images.unsplash.com/photo-1473594659356-a404044aa2c2?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  }
];

const Articles = () => {
  return (
    <Container maxWidth="1200px" mx="auto" my="auto" p={{ base: 5, md: 10 }}>
      <SimpleGrid columns={[1, 2, 3]} spacing="15px">
        {dataList.map((blog) => {
          return (
            <Box position="relative" key={blog.id}>
              <Box fontSize="sm" position="absolute" right="5px" margin="5px" zIndex="1">
                <Badge rounded="full" p="2px 8px" colorScheme="brand.primary.100" as="button">
                  Actualites
                </Badge>
              </Box>
              <Link to="#">
                <Box
                  borderWidth="1px"
                  shadow="md"
                  rounded="lg"
                  overflow="hidden"
                  position="relative"
                >
                  <Image src={blog.img} alt="Blog image" boxSize={"full"} objectFit="cover" />
                  <Box p={{ base: 4, lg: 6 }}>
                    <Box d="flex" alignItems="baseline">
                      <Box
                        fontWeight="semibold"
                        as="h2"
                        letterSpacing="wide"
                        textTransform="uppercase"
                        ml="2"
                      >
                        {blog.title}
                      </Box>
                    </Box>
                    <Box>
                      <Box color="gray.600" fontSize="sm">
                        <Badge rounded="full" px="2" colorScheme="teal">
                          {blog.authorName}
                        </Badge>
                      </Box>
                    </Box>
                    <Text
                      mt="1"
                      fontWeight="semibold"
                      noOfLines={3}
                      lineHeight="tight"
                      color="gray.600"
                      fontSize="sm"
                    >
                      {blog.content}
                    </Text>
                  </Box>
                </Box>
              </Link>
            </Box>
          );
        })}
      </SimpleGrid>
    </Container>
  );
};


const ArticleBlog = ({

}) => {
    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
    })
    const adminMenu=[
        {
            label: "Dernières informations",
            key: "infos",
            children: [],
            tooltip: "Dernières informations",
            show: true,
            icon: <FaNewspaper />,
            component: <Articles />
        }
    ]
    const [selectedMenu, setselectedMenu] = useState(adminMenu[0]);

    return (
        <Container >

            <HStack py={10} w={"100%"} >
                <Divider />
                <Wrap spacing='10px' justify='center' w={"full"}>
                   {adminMenu?.map(menu=>(<WrapItem>
                        <Tooltip label={menu?.tooltip}>
                            <Button
                                size={{ base: "xs", md: "md" }}
                                onClick={()=>setselectedMenu(menu)}
                                variant="secondary" leftIcon={menu?.icon} color={selectedMenu?.key===menu?.key? "white" :'brand.primary.100' } bg={selectedMenu?.key===menu?.key? "brand.primary.100" :'white'} fontSize={{ base: "sm", md: 'md' }}>
                                    {isDesktop && menu?.label}
                            </Button>
                        </Tooltip>
                    </WrapItem>)) }
                </Wrap>
                <Divider />
            </HStack>
            <Stack 
            spacing="24" direction="column" shouldWrapChildren
                shadow='lg' borderBottomWidth={6} borderBottomColor={"brand.primary.100"}
                w="auto"


            >
                {selectedMenu?.component}



            </Stack>

        </Container>);
}



export function Title({
    title,
    ...props
}) {
    return (
        <Center>
            <HStack>
                <Divider />
                <Button variant="secondary" {...props} >
                    {title}
                </Button>
                <Divider />
            </HStack>
        </Center>
    )
}
 export default ArticleBlog