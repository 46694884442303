import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FaFacebook, FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
import Logo from '../../Media/images/logo_ange1.png'
import { links } from './_data'

export const FooterApp = () => (
  <Box bg="brand.primary.100" color="white" as="section">
    <Container as="footer" role="contentinfo">
      <Stack
        justify="space-between"
        align="start"
        direction={{ base: 'column', lg: 'row' }}
        py={{ base: '12', md: '16' }}
        spacing="8"
      >
        <Stack spacing={{ base: '6', md: '8' }} align="start" maxW={"400px"}>
          <Image
            src={Logo}
            alt={"Sangue Logo"}
            width="auto"
            h={{ base: "64px", md: "76px" }}
            objectFit="cover"
            transition="all 0.2s"
            _groupHover={{ transform: 'scale(1.05)' }}
          />
          <Text color="fg.accent.muted">l'ANGE est une structure indépendante et permanente chargée de l’organisation et de la gestion de toutes les opérations électorales et référendaires.</Text>
        </Stack>
        <SimpleGrid columns={{ base: 1, md: 2, lg:4 }} gap="8" width={{ base: 'full', lg: 'auto' }}>
          {links.map((group, idx) => (
            <Stack key={idx} spacing="4" minW={{ lg: '40' }}>
              <Text fontSize="sm" fontWeight="semibold" color="fg.accent.subtle">
                {group.title}
              </Text>
              <Stack spacing="3" shouldWrapChildren>
                {group.links.map((link, idx) => (
                  <Button key={idx} as="a" variant="text.accent" href={link.href}>
                    {link.label}
                  </Button>
                ))}
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
      <Divider borderColor="bg.accent.subtle" />
      <Stack
        pt="8"
        pb="12"
        justify="space-between"
        direction={{ base: 'column-reverse', md: 'row' }}
        align="center"
      >
        <Text fontSize="sm" color="fg.accent.subtle">
          &copy; {new Date().getFullYear()} ANGE-Tchad. All rights reserved.
        </Text>
        <ButtonGroup variant="tertiary.accent">
          <IconButton as="a" href="#" aria-label="LinkedIn" icon={<FaLinkedin />} />
          <IconButton as="a" href="#" aria-label="GitHub" icon={<FaFacebook />} />
          <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter />} />
        </ButtonGroup>
      </Stack>
    </Container>
  </Box>
)
