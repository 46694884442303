import React from 'react';
import {
    Box,
    chakra,
    Container,
    Text,
    HStack,
    VStack,
    Flex,
    useColorModeValue,
    useBreakpointValue,
    Image,
    Stack
} from '@chakra-ui/react';
import img1 from "../../Media/images/depot.png"
import img2 from "../../Media/images/pub.png"
import img3 from "../../Media/images/elec.png"
import img4 from "../../Media/images/presi.jpg"
import img5 from "../../Media/images/result.png"
import img6 from "../../Media/images/vote.png"
const milestones = [
    {
        id: 1,
        date: 'Du 19 au 28 Octobre 2024',
        title: 'Dépôt de candidature',
        img: img1 ,
        description: `Winner of first ever ChakraUI Hackathon. On sait depuis longtemps que travailler avec du texte lisible et contenant du sens.`
    },
    {
        id: 2,
        date: 'Au 04 Novembre 2024',
        title: 'Publication de la liste provisoire des candidats pour les législatives, provinciales et municipales',
        img:  img2 ,
        description: `Fixing a typo, to fix a bug, contributing to Open Source and collaborating to improve technology for everyone, Ahmad's world changed again!.`
    },
    {
        id: 3,
        date: 'Au 16 Novembre 2024',
        title: 'Publication de la liste définitive pour les législatives',
        img: img2,
        description:
            'Ahmad starts his own business consulting for companies as a fullstack developer. Clients include UK Government departments, UK banks, global fintechs and startups.'
    },
    {
        id: 4,
        date: 'Au 24 Novembre 2024',
        title: 'Publication de la liste définitive des candidats pour les provinciales et municipales par la Cour Suprême',
        img: img2,
        description: `Winner of first ever ChakraUI Hackathon. On sait depuis longtemps que travailler avec du texte lisible et contenant du sens.`
    },
    {
        id: 5,
        date: 'Du 07 au 27 Décembre 2024',
        title: 'Campagne Electorale pour les élections législative, provinciales et communales',
        img:img6,
        description: `Fixing a typo, to fix a bug, contributing to Open Source and collaborating to improve technology for everyone, Ahmad's world changed again!.`
    },
    {
        id: 6,
        date: '29 Décembre 2024',
        title: 'Elections législatives, communales et provinciales',
        img:img3,
        description:
            'Ahmad starts his own business consulting for companies as a fullstack developer. Clients include UK Government departments, UK banks, global fintechs and startups.'
    },
    {
        id: 7,
        date: '15 Janvier 2025',
        title: 'Proclamation des résultats provisoires des Elections législatives, communales et provinciales (ANGE)',
        img:img5,
        description:
            'Ahmad starts his own business consulting for companies as a fullstack developer. Clients include UK Government departments, UK banks, global fintechs and startups.'
    },
    {
        id: 8,
        date: '03 Février 2025',
        title: 'Proclamation des résultats définitifs des Elections législatives, provinciales et communales (CS)',
        img:img5,
        description: `Fixing a typo, to fix a bug, contributing to Open Source and collaborating to improve technology for everyone, Ahmad's world changed again!.`
    }
];

const Milestones = () => {
    const isMobile = useBreakpointValue({ base: true, md: false });
    const isDesktop = useBreakpointValue({ base: false, md: true });

    return (
        <Container maxWidth="7xl" p={{ base: 2, sm: 10 }}>
            <chakra.h3 fontSize="4xl" fontWeight="bold" mb={18} textAlign="center">
                Dates Importantes
            </chakra.h3>
            {milestones.map((milestone) => (
                <Flex key={milestone.id} mb="10px">
                    {/* Desktop view(left card) */}
                    {isDesktop && milestone.id % 2 === 0 && (
                        <>
                            <EmptyCard />
                            <LineWithDot />
                            <Card {...milestone} />
                        </>
                    )}

                    {/* Mobile view */}
                    {isMobile && (
                        <>
                            <LineWithDot />
                            <Card {...milestone} />
                        </>
                    )}

                    {/* Desktop view(right card) */}
                    {isDesktop && milestone.id % 2 !== 0 && (
                        <>
                            <Card {...milestone} />

                            <LineWithDot />
                            <EmptyCard />
                        </>
                    )}
                </Flex>
            ))}
        </Container>
    );
};



const Card = ({ id, title, description, date, img }) => {
    // For even id show card on left side
    // For odd id show card on right side
    const isEvenId = id % 2 == 0;
    let borderWidthValue = isEvenId ? '15px 15px 15px 0' : '15px 0 15px 15px';
    let leftValue = isEvenId ? '-15px' : 'unset';
    let rightValue = isEvenId ? 'unset' : '-15px';

    const isMobile = useBreakpointValue({ base: true, md: false });
    if (isMobile) {
        leftValue = '-15px';
        rightValue = 'unset';
        borderWidthValue = '15px 15px 15px 0';
    }

    return (
        <Stack
            flex={1}
            p={{ base: 3, sm: 6 }}
            bg={useColorModeValue('gray.100', 'gray.800')}
            spacing={5}
            rounded="lg"
            alignItems="center"
            pos="relative"
            _before={{
                content: `""`,
                w: '0',
                h: '0',
                borderColor: `transparent ${useColorModeValue('#edf2f6', '#1a202c')} transparent`,
                borderStyle: 'solid',
                borderWidth: borderWidthValue,
                position: 'absolute',
                left: leftValue,
                right: rightValue,
                display: 'block'
            }}
            direction={{base: "column", md:"row"}}
        >
            <Box
            minW="150px"
            >
                <Image
                    rounded={'md'}
                    alt={'feature image'}
                    src={
                        img}
                    objectFit={'cover'}
                    boxSize="150px"
                    

                />
            </Box>
            <Box>
                <Text fontSize="lg" color={isEvenId ? 'brand.primary.100' : 'brand.secondary'}>
                    {date}
                </Text>

                <VStack spacing={2} mb={3} textAlign="left">
                    <chakra.h1 fontSize="lg" lineHeight={1.2} fontWeight="bold" w="100%">
                        {title}
                    </chakra.h1>
                    {/* <Text fontSize="md">{description}</Text> */}
                </VStack>
            </Box>
        </Stack>
    );
};

const LineWithDot = () => {
    return (
        <Flex
            pos="relative"
            alignItems="center"
            mr={{ base: '40px', md: '40px' }}
            ml={{ base: '0', md: '40px' }}
        >
            <chakra.span
                position="absolute"
                left="50%"
                height="calc(100% + 10px)"
                border="1px solid"
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                top="0px"
            ></chakra.span>
            <Box pos="relative" p="10px">
                <Box
                    pos="absolute"
                    top="0"
                    left="0"
                    bottom="0"
                    right="0"
                    width="100%"
                    height="100%"
                    backgroundSize="cover"
                    backgroundRepeat="no-repeat"
                    backgroundPosition="center center"
                    bg={useColorModeValue('gray.600', 'gray.200')}
                    borderRadius="100px"
                    backgroundImage="none"
                    opacity={1}
                ></Box>
            </Box>
        </Flex>
    );
};

const EmptyCard = () => {
    return <Box flex={{ base: 0, md: 1 }} p={{ base: 0, md: 6 }} bg="transparent"></Box>;
};

export default Milestones;