import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Redirect, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Task from "./Pages/Task";
import Administration from "./Pages/Administration";
import { GlobalContext } from "./Pages/Context";
import RedFlagDocument from "./Pages/RedFlag";
import Login from "./Pages/Login";
import Header from "./Pages/Header";
import { Box } from "@chakra-ui/react";
import Footer from "./Pages/Footer";
import Parametrage from "./Pages/Parametrage";
import Organisation from "./Pages/Organisation";
import { Donateur } from "./Pages/donateur";
import { Association } from "./Pages/association";
import Registration from "./Pages/Registration";
import Financement from "./Pages/Organisation/Financement";
import { BiHome } from "react-icons/bi";
import { FiBookmark, FiDollarSign, FiGrid, FiPieChart, FiSettings } from "react-icons/fi";
import { WebSocketDemo } from "./Pages/socket";
import Hebergement from "./Pages/Hebergement";
import HerbergementAdministration from "./Pages/HebergementAdmin";


const RouteApplication = () => {
    const { token, retrieve, dataUser } = useContext(GlobalContext)
    const menuData = [
        {
            label: "Accueil",
            key: "accueil",
            children: [],
            show: true,
            icon: BiHome,
            url: "/",
            component: <Home />
        },
        // {
        //     label: "Hebergement",
        //     key: "hebergement",
        //     children: [{
        //         label: "Location",
        //         key: "location",
        //         children: [],
        //         show: true,
        //         icon: FiDollarSign,
        //         url: "/location",
        //         component: <Donateur />
        //     },
        //     {
        //         label: "Reservation",
        //         key: "reservation",
        //         children: [],
        //         show: true,
        //         icon: FiBookmark,
        //         url: "/reservation",
        //         component: <Donateur />
        //     },
        //     {
        //         label: "Parametrage",
        //         key: "parametrage",
        //         children: [],
        //         show: true,
        //         icon: FiSettings,
        //         url: "/hparametrage",
        //         component: <HerbergementAdministration />
        //     }
        //     ],
        //     show: true,
        //     icon: FiGrid,
        //     url: "/"
        // },
    ]

    return (
        <>
            {/* <BrowserRouter> */}
            <Routes>

                <Route exact path="/" element={
                    <>
                        <Header menuData={menuData} />
                        <Box minH="100vh" as="section" minW={{ base: "full", lg: "90%" }} pb={5}>
                            <Home />
                        </Box>
                        <Footer />
                    </>

                } />
        
                {menuData?.map(child => (
                    <Route exact path={`/${child?.key}`} element={<>
                        <Header menuData={menuData} />
                        <Box minH="100vh" as="section" w={{ base: "full", lg: "90%" }} pb={5}>
                            <Login />
                        </Box>
                        <Footer />
                    </>} />
                ))

                }

                <Route exact path="/login" element={token ?
                    <>
                        <Header menuData={menuData} />
                        <Box minH="100vh" as="section" minW={{ base: "100%", md: "100%", lg: "90%" }} pb={5}>
                            <Home />
                        </Box>
                        <Footer />
                    </> :
                    <Login />
                } />
                <Route exact path="/administration" element={
                    <>
                        <Header menuData={menuData} />
                        <Box minH="100vh" as="section" 
                        
                        >
                            <Administration />
                        </Box>
                        <Footer />
                    </>

                } />
                  <Route exact path="/hebergement" element={
                    <>
                        <Header menuData={menuData} />
                        <Box minH="100vh" as="section" 
                        
                        >
                            <Hebergement />
                        </Box>
                        <Footer />
                    </>

                } />
                  <Route exact path="/hparametrage" element={
                    <>
                        <Header menuData={menuData} />
                        <Box minH="100vh" as="section" 
                        
                        >
                            <HerbergementAdministration />
                        </Box>
                        <Footer />
                    </>

                } />
                <Route exact path="/*" element={
                    <>
                        <Header menuData={menuData} />
                        <Box minH="100vh" as="section" w={{ base: "full", md: "100%", lg: "90%" }} >
                            <Home />
                        </Box>
                        <Footer />
                    </>
                } />

            </Routes>
            {/* </BrowserRouter> */}
        </>
    );
}

export default RouteApplication;