import {
  Box,
  Button,
  Heading,
  Img,
  Stack,
  Text,
  Accordion,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { FiSend } from 'react-icons/fi'
import { HiPlay } from 'react-icons/hi'
import { Link } from "react-router-dom";
import bg2 from './../../Media/images/presi.jpg'
import { AccordionMotion } from './Accordion';
import { useTranslation } from 'react-i18next';
import { FacebookShare } from 'react-share-kit'
export function Apropos() {
  const [expanded, setExpanded] = React.useState(0);
  const { t } = useTranslation()
  const activites = [{
    id: 0,
    title: "Mentorat personnalisé",
    content: " Chaque artiste est jumelé avec un mentor expérimenté dans son domaine, qui offre des conseils et un soutien individualisé pour aider à perfectionner les compétences artistiques et professionnelles.",
  },
  {
    id: 1,
    title: "Résidences artistiques",
    content: " Miti Nga propose des programmes de résidence où les artistes peuvent se concentrer sur leur travail dans un environnement inspirant et collaboratif, souvent avec accès à des ressources techniques et logistiques.",
  },
  {
    id: 3,
    title: "Formation et ateliers ",
    content: "Les sessions de formation sont organisées pour renforcer les compétences pratiques et théoriques des artistes, couvrant des sujets tels que la gestion de carrière, la promotion artistique et la gestion financière.",
  },
  {
    id: 4,
    title: "Réseautage et collaborations ",
    content: " Miti Nga facilite les opportunités de réseautage avec d'autres artistes, professionnels de l'industrie et institutions culturelles, encourageant les collaborations et les partenariats stratégiques.",
  },
  {
    id: 5,
    title: "Promotion et visibilité",
    content: "La structure aide à promouvoir le travail des artistes incubés à travers divers canaux médiatiques et plateformes en ligne, augmentant ainsi leur visibilité et leur accès à de nouveaux publics.",
  },
  ]
  return (
    <Box as="section" pb="10">
      <Box maxW={{ base: 'auto', md: 'full' }} mx="auto" px={{ base: '6', md: '8' }}>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: '3rem', lg: '2rem' }}
          mt="8"
          align={{ lg: 'center' }}
          justify="space-between"
        >
          <Box pos="relative" w={{ base: 'full', lg: '40%' }} h={{ base: 'auto', lg: '100%' }}>
            <Img
              w="full"
              pos="relative"
              zIndex="1"
              h={{ lg: 'full' }}
              objectFit="cover"
              src={bg2}
              alt="Screening talent"
            />
            <Box
              pos="absolute"
              w="100%"
              h="100%"
              top="-6"
              left="-4"
              bgGradient='linear(to-tl,brand.jaune.900, brand.bleu.900)'
            />
          </Box>
          <Box flex="1" maxW={{ lg: '55%' }}>
            <Heading
              as="h1"
              size={{ base: "md", md: "lg" }}
              color={"brand.secondary"}
              mt="8"
              fontWeight="extrabold"
              letterSpacing="tight"
              textAlign='center'
            >
              Agence Nationale de Gestion des Élections
            </Heading>
            <Text color={mode('white', 'white')} mt="4" fontWeight="medium" fontSize={"md"}>
              {/* Cette plateforme digitale vise à faciliter la mise en relation des organisations de la société civile et des partenaires techniques et financiers et à recenser les opportunités de financements pour les organisations de la société civile au Sahel.  */}
              {`Elle a pour mission d'Organiser le recensement électoral, assurer la mise à jour du fichier électoral, constituer, gérer et conserver le fichier électoral national, arrêter et publier les listes électorales, conformément aux dispositions du Code électoral, réaliser la cartographie.


L'Agence Nationale de Gestion des Elections (ANGE) est une institution qui a pour missions d'organiser et de gérer toutes les opérations électorales.


Elle est une structure indépendante et permanente créée par une loi organique promulguée par le Président de la République le 29 janvier 2024. Elle jouit d'une autonomie de gestion.


L'ANGE est composée de 15 membres, nommés par décret pour un mandat de sept ans. Ils jouissent de l'immunité pendant l'exercice de leur fonction.  Avant leur prise de fonction, les membres de l'ANGE prêtent serment devant le Conseil constitutionnel. L'actuel Président est Ahmat Bartchiret.


Les Organes de l'ANGE sont : la Plénière, le Bureaux et les Directions.`}

            </Text>
            {/* <Accordion

            >
              {activites.map((item) => (
                <AccordionMotion i={item?.id} expanded={expanded} setExpanded={setExpanded} {...item} />
              ))}
            </Accordion> */}
          </Box>

        </Stack>
      </Box>
    </Box>
  )
}