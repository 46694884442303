import { Accordion, Container, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { JobPosting } from './JobPosting'
import { jobListings } from './data'

export const Carrers = () => (
  <Container py={{ base: '16', md: '24' }} color={"white"}      mb={10}  >
    <SimpleGrid columns={{ base: 1, md: 2 }} gap={12} >
      <Stack spacing={{ base: '4', md: '6' } }>
        <Stack spacing="4">
          {/* <Text fontWeight="semibold" color="accent" textStyle={{ base: 'sm', md: 'md' }}>
            Careers
          </Text> */}
          <Heading as="h1" size={{ base: 'md', md: 'lg' }} color={"brand.secondary"}>
          Postes à pourvoir 
          </Heading>
        </Stack>
        <Text textStyle={{ base: 'lg', md: 'xl' }} color="white">
        Découvrez les offres d'emploi passionnantes de notre entreprise et rejoignez une équipe dynamique.
        </Text>
      </Stack>
      <Accordion defaultIndex={0}>
        {jobListings.map((listing, id) => (
          <JobPosting key={id} {...listing} />
        ))}
      </Accordion>
    </SimpleGrid>
  </Container>
)
